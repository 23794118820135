import LocalStore from 'utils/LocalStore';
import * as UserTypes from 'features/user/userTypes';
import { store } from 'store/store';
import Auth from 'auth/Auth';

export const checkAuth = () => {
  const jwt = LocalStore.getAccessToken();
  // const pgtIou = LocalStore.getCasPgtIou();

  // if (pgtIou && !jwt) {
  //   console.log("initial login");
  // }
  if (!jwt) {
    return false;
  } else if (
    Auth.getLoginMode() === Auth.LOGIN_MODE_HST &&
    Auth.isJwtExpired(jwt)
  ) {
    return false;
  }
  store.dispatch({
    type: UserTypes.JWT,
    payload: jwt
  });
  return true;
};

