import { Box, Button, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, Grid, makeStyles, Radio, RadioGroup, TextField, Typography } from '@material-ui/core';
import { AppConfig } from 'AppConfig';
import bstLogo from 'assets/img/BenInsights-Blue.png';
import axios from 'axios';
import moment from 'moment-timezone';
import React, { useEffect, useRef } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import RouteConfig from 'routes/RouteConfig';
import * as AppActionTypes from 'store/actions/appstate';
import { store } from 'store/store';
import AdminChart from './adminChart';
import ContactInfo from './contactInfo';
import MedicalChart from './medicalChart';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  bigFont: { fontSize: '1.5rem !important' },
  formControl: {
    marginBottom: theme.spacing(-1)
  },
  imgContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputField: {
    margin: theme.spacing(1),
    '& .MuiInputBase-root': {
      height: '25px'
    },
    width: '150px'
  },
  label: {
    fontWeight: '500',
    marginRight: theme.spacing(1),
    alignSelf: 'center'
  },
  leftFill: { marginLeft: '25px' },
  smallFont: { fontSize: '0.8rem !important' }
}));

const ClientPortalQuestionary = (props) => {
  const methods = useForm();
  const { control, formState, getValues } = methods;
  const currentTenant = useSelector((state) => state.user.companies.filter((c) => c.code === state.user.currentTenantCd));
  const [state, setState] = React.useState({
    caseId: props?.location?.state?.caseId,
    newPlanYearStart: props?.location?.state?.newPlanStartYear
      ? moment(props?.location?.state?.newPlanStartYear, 'MM/DD/YYYY').format('MM-YYYY')
      : moment().startOf('year').add(1, 'years').format('MM-YYYY'),
    tenantCode: props?.location?.state?.tenantCode,
    tenantName: props?.location?.state?.tenantName,
    renewalAccountType: props?.location?.state?.renewalAccountType,
    comments: '',

    carrierChange: false,
    billingChange: false,

    carrierMedical: false,
    carrierPharmacy: false,
    carrierEligibility: false,
    carrierAdmin: false,
    carrierBiometrics: false,
    carrierWellness: false,
    carrierOther: false
  });

  const styles = useStyles();
  const history = useHistory();
  const CLIENT_PORTAL_DASHBOARD_URL = RouteConfig.clientPortal.path;

  const componentRef = useRef();

  const onSubmit = (data) => {
    let myData = { ...state, ...getValues(), submitTime: moment(new Date()).format('MM/DD/YYYY HH:mm UTC') };
    axios.post(AppConfig.CLIENT_PORTAL_URL + '/case-management/questionnaire', myData).then(
      (response) => {
        // console.log(response);
        history.push(CLIENT_PORTAL_DASHBOARD_URL);
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const isSubmitEnabled = () => {
    const { carrierChangeYN, billingChangeYN, ...others } = getValues();

    /* user has to choose whether there are carrier changes and billing changes. */
    if (!carrierChangeYN || !billingChangeYN) {
      return false;
    }

    /* if carrier change is true, then the user has to provide some carrier change information. */
    if (state.carrierChange && Object.keys(state).filter((key) => key.startsWith('carrier') && key !== 'carrierChange' && state[key]).length === 0) {
      return false;
    }

    /* if billing change is true, then user has to specify some billing change information. */
    if (state.billingChange && Object.keys(others).filter((key) => key.startsWith('billing') && others[key]).length === 0) {
      return false;
    }

    /* if there are carrier or billing changes, then user has to provide detailed change information. */
    if ((state.carrierChange || state.billingChange) && Object.keys(others).filter((key) => others[key] !== undefined && others[key] !== null && others[key] !== '').length === 0) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    store.dispatch({
      type: AppActionTypes.SHOW_BST_BAR_TENANT,
      payload: false
    });
    return () => {
      store.dispatch({
        type: AppActionTypes.SHOW_BST_BAR_TENANT,
        payload: true
      });
    };
  }, []);

  return (
    <FormProvider {...methods}>
      <div ref={componentRef}>
        <>
          {currentTenant && currentTenant[0] ? (
            <>
              <div className={styles.imgContainer}>
                <Box component="img" src={bstLogo} />
              </div>
              <div align="center">
                <br />
                <Typography variant="h6" color="primary">
                  {state.tenantName}
                </Typography>
              </div>
              <div align="center" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <label htmlFor="planStartYear" className={styles.label}>
                  Plan Year Start: {state.newPlanYearStart}
                </label>
              </div>
              <br />
              <Divider color="primary" />

              <Typography color="primary">
                <strong style={{ fontSize: '1.15em' }}>Are there any carrier changes or additions from what is being loaded to BenInsights, in the coming plan year?</strong>
                <br />
                <span className={styles.smallFont}>(Excluding Stop Loss)</span>
              </Typography>
              <Grid item xs={6}>
                <FormControl component="fieldset">
                  <Controller
                    name="carrierChangeYN"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <RadioGroup
                        row
                        {...field}
                        onChange={(e) => {
                          field.onChange(e);
                          setState({ ...state, carrierChange: e.target.value === 'yes' });
                        }}>
                        <FormControlLabel value="yes" control={<Radio style={{ color: 'blue' }} />} label="Yes" />
                        <FormControlLabel
                          value="no"
                          control={
                            <Radio
                              style={{ color: 'blue' }}
                              onClick={() => {
                                  setState({
                                    ...state,
                                    carrierChange: false,
                                    carrierMedical: false,
                                    carrierPharmacy: false,
                                    carrierEligibility: false,
                                    carrierAdmin: false,
                                    carrierBiometrics: false,
                                    carrierWellness: false,
                                    carrierOther: false
                                  });
                              }}
                            />
                          }
                          label="No"
                        />
                      </RadioGroup>
                    )}
                  />
                </FormControl>
              </Grid>
            </>
          ) : (
            <p>Invalid Tenant</p>
          )}

          {state.carrierChange && (
            <>
              <div>
                <Typography color="primary" style={{ display: 'inline-block' }}>
                  <strong style={{ fontSize: '1.15em' }}>Changing or adding data feeds:</strong>
                  <br />
                  <span className={styles.smallFont}>
                    (Please identify any change or additional data that you would like to include and click all that apply. There changes could incur a fee as outlined in the
                    estimate contract.)
                  </span>
                </Typography>
              </div>
              <div className={styles.root}>
                <FormControl component="fieldset" className={styles.formControl}>
                  <FormGroup column>
                    <FormControlLabel
                      control={<Checkbox checked={state.carrierMedical} onChange={() => setState({ ...state, carrierMedical: !state.carrierMedical })} name="medical" />}
                      label="Medical"
                    />
                    <FormControlLabel
                      control={<Checkbox checked={state.carrierPharmacy} onChange={() => setState({ ...state, carrierPharmacy: !state.carrierPharmacy })} name="pharmacy" />}
                      label="Pharmacy"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox checked={state.carrierEligibility} onChange={() => setState({ ...state, carrierEligibility: !state.carrierEligibility })} name="eligibility" />
                      }
                      label="Eligibility"
                    />
                  </FormGroup>
                </FormControl>
                <FormControl required component="fieldset" className={styles.formControl}>
                  <FormGroup column className={styles.leftFill}>
                    <FormControlLabel
                      control={<Checkbox checked={state.carrierAdmin} onChange={() => setState({ ...state, carrierAdmin: !state.carrierAdmin })} name="benAdmin" />}
                      label="BenAdmin Eligibility"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox checked={state.carrierBiometrics} onChange={() => setState({ ...state, carrierBiometrics: !state.carrierBiometrics })} name="biometrics" />
                      }
                      label="Biometrics"
                    />
                    <FormControlLabel
                      control={<Checkbox checked={state.carrierWellness} onChange={() => setState({ ...state, carrierWellness: !state.carrierWellness })} name="wellness" />}
                      label="Wellness"
                    />
                    <FormControlLabel
                      control={<Checkbox checked={state.carrierOther} onChange={() => setState({ ...state, carrierOther: !state.carrierOther })} name="other" />}
                      label="Other"
                    />
                  </FormGroup>
                </FormControl>
              </div>
              {state.carrierMedical && <MedicalChart prefix="medical" />}
              {state.carrierPharmacy && <MedicalChart prefix="pharmacy" />}
              {state.carrierEligibility && <MedicalChart prefix="eligibility" />}
              {state.carrierAdmin && <AdminChart prefix="benAdmin" />}
              {state.carrierBiometrics && <AdminChart prefix="biometrics" />}
              {state.carrierWellness && <AdminChart prefix="wellness" />}
              {state.carrierOther && <AdminChart prefix="other" />}
            </>
          )}

          <Typography color="primary">
            <strong style={{ fontSize: '1.15em' }}>Billing Changes:</strong>
            <br />
            <span className={styles.smallFont}>(Has the billing contact or responsible party changed?)</span>
          </Typography>
          <Grid item xs={6}>
            <FormControl component="fieldset">
              <Controller
                name="billingChangeYN"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <RadioGroup
                    row
                    {...field}
                    onChange={(e) => {
                      field.onChange(e);
                      setState({ ...state, billingChange: e.target.value === 'yes' });
                    }}>
                    <FormControlLabel value="yes" control={<Radio style={{ color: 'blue' }} />} label="Yes" />
                    <FormControlLabel
                      value="no"
                      control={
                        <Radio
                          style={{ color: 'blue' }}
                          onClick={() => {
                              setState({ ...state, billingChange: false });
                          }}
                        />
                      }
                      label="No"
                    />
                  </RadioGroup>
                )}
              />
            </FormControl>
          </Grid>

          {state.billingChange && (
            <>
              <Grid container spacing={2} style={{ marginLeft: '4px' }}>
                <Grid item xs={3}>
                  <Grid container alignItems="center">
                    <Grid item>
                      <label htmlFor={'billingEntityName'}>Legal Entity Name</label>
                    </Grid>
                    <Grid item xs>
                      <Controller
                        name="billingEntityName"
                        control={control}
                        defaultValue=""
                        rules={{ required: state.billingChange }}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            fullWidth
                            margin="dense"
                            size="small"
                            className={styles.inputField}
                            variant="outlined"
                            required={state.billingChange}
                            error={!!fieldState.error}
                            helperText={fieldState.error ? 'Legal Entity Name is required' : ''}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={3}>
                  <Grid container alignItems="center" style={{ marginLeft: '30px' }}>
                    <Grid item>
                      <label htmlFor={'billingAddress'}>Billing Address</label>
                    </Grid>
                    <Grid item xs>
                      <Controller
                        name="billingAddress"
                        control={control}
                        defaultValue=""
                        rules={{ required: state.billingChange }}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            fullWidth
                            margin="dense"
                            size="small"
                            className={styles.inputField}
                            variant="outlined"
                            error={!!fieldState.error}
                            helperText={fieldState.error ? 'Billing Address is required' : ''}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <ContactInfo prefix="billing" />
            </>
          )}

          <Typography color="primary">
            <strong style={{ fontSize: '1.15em' }}>Additional Comments</strong>
            <span className={styles.smallFont}>&nbsp;- optional</span>
          </Typography>
          <Controller
            name="comments"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                fullWidth
                margin="dense"
                size="small"
                variant="outlined"
                multiline
                minRows={4}
                onChange={(e) => {
                  field.onChange(e);
                  setState({ ...state, comments: e.target.value });
                }}
                error={!!fieldState.error}
              />
            )}
          />
          <p align="center">
            Contact{' '}
            <a href="mailto: insights-renewals@multiplan.com" style={{ textDecoration: 'underline' }}>
              Insights-Renewals
            </a>{' '}
            with any questions.
          </p>
          <br />
          <Button
            key={Object.values(state).join('-') + '-' + formState.dirtyFields.billingChangeYN}
            style={{
              backgroundColor: isSubmitEnabled() ? '#377DC7': '#B0C4DE',
              height: '40px',
              width: '140px',
              marginTop: '5px',
              color: 'white',
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
              fontSize: '1.1em'
            }}
            onClick={methods.handleSubmit(onSubmit)}
            disabled={!isSubmitEnabled()}>
            Submit
          </Button>
        </>
      </div>
    </FormProvider>
  );
};

export default ClientPortalQuestionary;
