import Cookies from 'js-cookie';
import { parseDomain, ParseResultType, Validation  } from "parse-domain"

const BST_DEVICE = 'bstDeviceToken';
const CHANGING_URL = '@Changingurl';
const CURRENT_APP = '@cerrntapp';
const FRESH_CLICKED = '@freshclick';
const LOCAL_STORE_TOKEN = '@token';
const LOCAL_STORE_REFRESH_TOKEN = '@refresh';
const LOGIN_USER_NAME = '@loginuser';
const LOGIN_USER_ID = '@loginuserid';
const MSG_READ = '@msgread';
const VERIFY_OTP = '@verifyotp';
const CAS_PGT_IOU = '@PgtIou';
const LOGIN_MODE = '@loginMode';
const LOCAL_HOST_NAMES=["localhost", "127.0.0.1"];

class LocalStore {

  clear() {
    let myDomain = this.findInternalDomain();
    Cookies.remove(LOCAL_STORE_TOKEN, { domain: myDomain, expires: 7, path: '/'});
    Cookies.remove(CAS_PGT_IOU, { domain: myDomain, path: '/' });
    // tempory solution for the token removal issue
    Cookies.remove(LOCAL_STORE_TOKEN, {expires: 7});
    Cookies.remove(CAS_PGT_IOU, {expires: 7});
    Cookies.set(LOCAL_STORE_REFRESH_TOKEN, '');
    Cookies.remove(CURRENT_APP);
    Cookies.remove(LOGIN_USER_NAME);
    Cookies.remove(LOGIN_USER_ID);
    Cookies.set(MSG_READ, '');
    Cookies.set(VERIFY_OTP, 'false');
    Cookies.set(FRESH_CLICKED, 'no');
    Cookies.set(CHANGING_URL, 'no');
  }

  saveBstDevice(token) {
    if (localStorage.getItem(BST_DEVICE)) {
      if (localStorage.getItem(BST_DEVICE).indexOf(token) === -1) {
        localStorage.setItem(
          BST_DEVICE,
          localStorage.getItem(BST_DEVICE) + ':' + token
        );
      }
    } else {
      localStorage.setItem(BST_DEVICE, token);
    }
  }

  getBstDevice() {
    return localStorage.getItem(BST_DEVICE);
  }

  saveAccessToken(token) {
    let myDomain = this.findInternalDomain();
    Cookies.set(LOCAL_STORE_TOKEN, token, { domain: myDomain, expires: 7, path: '/'});
  }

  getAccessToken() {
    return Cookies.get()[LOCAL_STORE_TOKEN];
  }

  saveChangingUrl(item) {
    Cookies.set(CHANGING_URL, item);
  }
  getChangingUrl() {
    return Cookies.get(CHANGING_URL);
  }

  saveFreshClicked(item) {
    Cookies.set(FRESH_CLICKED, item);
  }
  getFreshClicked() {
    return Cookies.get(FRESH_CLICKED);
  }

  saveRefreshToken(token) {
    Cookies.set(LOCAL_STORE_REFRESH_TOKEN, token, { expires: 7 });
  }
  getRefreshToken() {
    return Cookies.get(LOCAL_STORE_REFRESH_TOKEN);
  }

  saveLoginUser(name) {
    localStorage.setItem(LOGIN_USER_NAME, name);
  }

  getLoginUser() {
    return localStorage.getItem(LOGIN_USER_NAME);
  }

  saveCurrentApp(name) {
    localStorage.setItem(CURRENT_APP, name);
  }

  getCurrentApp() {
    return localStorage.getItem(CURRENT_APP);
  }
  saveMsgRead(name) {
    localStorage.setItem(MSG_READ, name);
  }

  getMsgRead() {
    return localStorage.getItem(MSG_READ);
  }

  saveVerifyOtp(name) {
    localStorage.setItem(VERIFY_OTP, name);
  }

  getVerifyOtp() {
    return localStorage.getItem(VERIFY_OTP);
  }

  saveCasPgtIou(value) {
    let myDomain = this.findInternalDomain();
    Cookies.set(CAS_PGT_IOU, value, { domain: myDomain, path: '/' });
  }
  getCasPgtIou() {
    return Cookies.get()[CAS_PGT_IOU];
  }

  /* do not call this method directly, use Auth.setLoginMode(value) instead. */
  setLoginMode(value) {
    Cookies.set(LOGIN_MODE, value, { expires: 7 });
  }
  removeLoginMode() {
    Cookies.remove(LOGIN_MODE);
  }
  /* do not call this mehtod directly, use Auth.getLoginMode() instead. */
  getLoginMode() {
    return Cookies.get(LOGIN_MODE);
  }

  isLoginModeToken(cookieName) {
    return cookieName === LOGIN_MODE;
  }

  findInternalDomain(hostname) {
    let parseResult=undefined;
    if (hostname) {
      parseResult = parseDomain(hostname, {validation: Validation.Lax});
    } else {
      parseResult = parseDomain(window.location.hostname, {validation: Validation.Lax});
    }
    if (parseResult.type === ParseResultType.Invalid) {
      return null;
    }

    if (LOCAL_HOST_NAMES.includes(window.location.hostname) || parseResult.type === ParseResultType.Ip) {
      return window.location.hostname;
    }

    if (parseResult.type === ParseResultType.Listed ) {
      let { subDomains, domain, topLevelDomains } = parseResult;
      return domain + "." + topLevelDomains.join(".")
    }
    return null;
  };
}

export default new LocalStore();
