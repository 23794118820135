import { Box, Chip, Grid, makeStyles, Typography } from '@material-ui/core';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';
import red from '@material-ui/core/colors/red';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import DoneIcon from '@material-ui/icons/Done';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary
} from 'common/BstAccordion';
import { ANNUAL_PLAN_RENEWAL_STAGES, CASE_STATUS, RENEWAL_ACCOUNT_TYPE } from 'common/Constant';
import { INVALID_ANNUAL_PLAN_RENEWAL_CASE_STATUS } from 'common/error';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import RouteConfig from 'routes/RouteConfig';
import { v4 as uuid } from 'uuid';
import { store } from 'store/store';
import * as AppActionTypes from 'store/actions/appstate';
import { getAnnualPlanRenewalData } from '../modules/action';
import AnnualPlanRenewalDetailPanel from './detail/AnnualPlanRenewalDetailPanel';

const AnnualPlanRenewal = () => {
  const dispatch = useDispatch();
  const { annualPlanRenewalList } = useSelector((state) => state.clientPortal);

  const useStyles = makeStyles(() => ({
    pending: {
      backgroundColor: alpha(red[500], 0.7),
      width: '160px'
    },
    done: {
      backgroundColor: alpha(green[500], 0.7),
      width: '160px'
    },
    notRequired: {
      backgroundColor: grey[200],
      width: '160px'
    },
    processing: {
      backgroundColor: alpha(blue[800], 0.7),
      width: '160px'
    },
    todo: {
      backgroundColor: alpha(grey[500], 0.7),
      width: '160px'
    },
    accordionSummary: {
      flexDirection: 'row-reverse'
    }
  }));
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    dispatch(getAnnualPlanRenewalData());
    store.dispatch({
      type: AppActionTypes.SHOW_BST_BAR_TENANT,
      payload: false
    });
    return () => {
      store.dispatch({
        type: AppActionTypes.SHOW_BST_BAR_TENANT,
        payload: true
      });
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const routeToQuestionary = () => {
    if (Array.isArray(annualPlanRenewalList) && annualPlanRenewalList.length > 0) {
      history.push({
        pathname: RouteConfig.clientPortalQuestionary.path,
        state: {
          caseId: annualPlanRenewalList[0]?.caseId,
          newPlanStartYear: annualPlanRenewalList[0]?.caseDueDate,
          tenantCode: annualPlanRenewalList[0]?.tenantCode,
          tenantName: annualPlanRenewalList[0]?.tenantName,
          renewalAccountType: annualPlanRenewalList[0]?.renewalAccountType
        }
      });
    }
  };
  const getComponent = (rowData, stage) => {
    const { renewalAccountType, status } = rowData || {};
    
    const renderChipWithLabel = (label, className, onClick = null, dueDate = null) => (
        <>
            <Chip key={'key-' + uuid()} label={label} className={className} onClick={onClick} />
            <br />
            <Typography variant="caption" style={{paddingLeft: '2.5rem'}}>{dueDate ? `Due ${dueDate}` : '\u00A0'}</Typography>
        </>
    );
    
    const renderChip = (label, className, icon = null) => (
        <>
            <Chip key={'key-' + uuid()} label={label} className={className} icon={icon} />
            {(status === CASE_STATUS.PENDING_CHANGE_FORM || status === CASE_STATUS.PENDING_MD_FILE) ? <><br /><Typography variant="caption">&nbsp;</Typography></>  : null}
        </>
    );
    
    switch (stage) {
        case ANNUAL_PLAN_RENEWAL_STAGES.RENEWAL_QUESTIONNAIRE.value:
            switch (true) {
                case renewalAccountType === RENEWAL_ACCOUNT_TYPE.CORE && status === CASE_STATUS.PENDING_CHANGE_FORM:
                case renewalAccountType === RENEWAL_ACCOUNT_TYPE.ENHANCED && status === CASE_STATUS.PENDING_CHANGE_FORM:
                    return renderChipWithLabel(
                        <u>{ANNUAL_PLAN_RENEWAL_STAGES.RENEWAL_QUESTIONNAIRE.label}</u>,
                        classes.pending,
                        routeToQuestionary,
                        rowData?.surveyDueDate
                    );
    
                case renewalAccountType === RENEWAL_ACCOUNT_TYPE.CORE && status === CASE_STATUS.READY_TO_RENEW:
                case renewalAccountType === RENEWAL_ACCOUNT_TYPE.ENHANCED && status === CASE_STATUS.PENDING_MD_FILE:
                case renewalAccountType === RENEWAL_ACCOUNT_TYPE.ENHANCED && status === CASE_STATUS.READY_TO_RENEW:
                    return renderChip(null, classes.done, <DoneIcon fontSize="medium" />);
    
                case renewalAccountType === RENEWAL_ACCOUNT_TYPE.MD_ONLY && status === CASE_STATUS.PENDING_MD_FILE:
                case renewalAccountType === RENEWAL_ACCOUNT_TYPE.MD_ONLY && status === CASE_STATUS.READY_TO_RENEW:
                    return renderChip(ANNUAL_PLAN_RENEWAL_STAGES.RENEWAL_QUESTIONNAIRE.notRequired, classes.notRequired);
    
                default:
                    // If the renewal case data is invalid, display the error message in the second column.
                    return <>{INVALID_ANNUAL_PLAN_RENEWAL_CASE_STATUS}</>;
            };
        case ANNUAL_PLAN_RENEWAL_STAGES.ANNUAL_RENEWAL_UPLOAD.value:
            switch (true) {
                case renewalAccountType === RENEWAL_ACCOUNT_TYPE.CORE && status === CASE_STATUS.PENDING_CHANGE_FORM:
                case renewalAccountType === RENEWAL_ACCOUNT_TYPE.CORE && status === CASE_STATUS.READY_TO_RENEW:
                    return renderChip(ANNUAL_PLAN_RENEWAL_STAGES.ANNUAL_RENEWAL_UPLOAD.notRequired, classes.notRequired);
    
                case renewalAccountType === RENEWAL_ACCOUNT_TYPE.MD_ONLY && status === CASE_STATUS.PENDING_MD_FILE:
                case renewalAccountType === RENEWAL_ACCOUNT_TYPE.ENHANCED && status === CASE_STATUS.PENDING_MD_FILE:
                    return renderChipWithLabel(
                        <u>{ANNUAL_PLAN_RENEWAL_STAGES.ANNUAL_RENEWAL_UPLOAD.label}</u>,
                        classes.pending,
                        null,
                        rowData?.mdFileDueDate
                    );
    
                case renewalAccountType === RENEWAL_ACCOUNT_TYPE.MD_ONLY && status === CASE_STATUS.READY_TO_RENEW:
                case renewalAccountType === RENEWAL_ACCOUNT_TYPE.ENHANCED && status === CASE_STATUS.READY_TO_RENEW:
                    return renderChip(null, classes.done, <DoneIcon fontSize="medium" />);
    
                case renewalAccountType === RENEWAL_ACCOUNT_TYPE.ENHANCED && status === CASE_STATUS.PENDING_CHANGE_FORM:
                    return renderChipWithLabel(
                        ANNUAL_PLAN_RENEWAL_STAGES.ANNUAL_RENEWAL_UPLOAD.label,
                        classes.todo,
                        null,
                        rowData?.mdFileDueDate
                    );
    
                default:
                    return <></>;
            }
        case ANNUAL_PLAN_RENEWAL_STAGES.PROCESSING.value:
            switch (true) {
                case renewalAccountType === RENEWAL_ACCOUNT_TYPE.CORE && status === CASE_STATUS.PENDING_CHANGE_FORM:
                case renewalAccountType === RENEWAL_ACCOUNT_TYPE.MD_ONLY && status === CASE_STATUS.PENDING_MD_FILE:
                case renewalAccountType === RENEWAL_ACCOUNT_TYPE.ENHANCED && status === CASE_STATUS.PENDING_CHANGE_FORM:
                case renewalAccountType === RENEWAL_ACCOUNT_TYPE.ENHANCED && status === CASE_STATUS.PENDING_MD_FILE:
                    return renderChip(ANNUAL_PLAN_RENEWAL_STAGES.PROCESSING.label, classes.todo);
    
                case renewalAccountType === RENEWAL_ACCOUNT_TYPE.CORE && status === CASE_STATUS.READY_TO_RENEW:
                case renewalAccountType === RENEWAL_ACCOUNT_TYPE.MD_ONLY && status === CASE_STATUS.READY_TO_RENEW:
                case renewalAccountType === RENEWAL_ACCOUNT_TYPE.ENHANCED && status === CASE_STATUS.READY_TO_RENEW:
                    return renderChip(ANNUAL_PLAN_RENEWAL_STAGES.PROCESSING.label, classes.processing);
    
                default:
                    return <></>;
            }
        case ANNUAL_PLAN_RENEWAL_STAGES.COMPLETED.value:
            switch (true) {
                case renewalAccountType === RENEWAL_ACCOUNT_TYPE.CORE && status === CASE_STATUS.PENDING_CHANGE_FORM:
                case renewalAccountType === RENEWAL_ACCOUNT_TYPE.CORE && status === CASE_STATUS.READY_TO_RENEW:
                case renewalAccountType === RENEWAL_ACCOUNT_TYPE.MD_ONLY && status === CASE_STATUS.PENDING_MD_FILE:
                case renewalAccountType === RENEWAL_ACCOUNT_TYPE.MD_ONLY && status === CASE_STATUS.READY_TO_RENEW:
                case renewalAccountType === RENEWAL_ACCOUNT_TYPE.ENHANCED && status === CASE_STATUS.PENDING_CHANGE_FORM:
                case renewalAccountType === RENEWAL_ACCOUNT_TYPE.ENHANCED && status === CASE_STATUS.PENDING_MD_FILE:
                case renewalAccountType === RENEWAL_ACCOUNT_TYPE.ENHANCED && status === CASE_STATUS.READY_TO_RENEW:
                    return renderChip(ANNUAL_PLAN_RENEWAL_STAGES.COMPLETED.label, classes.todo);
    
                default:
                    return <></>;
            }
        default:
            return <></>;

        
    }
  };

  return (
    <>
        {annualPlanRenewalList?.map((rowData, i) => 
          <Accordion key={'key-' + rowData.caseId + i}>
              <AccordionSummary
                  id='annual-plan-renewal-list-header'
                  expandIcon={<ExpandMoreIcon />}
                  className={classes.accordionSummary}
              >
                  <Grid container spacing={5} justifyContent='center' alignItems='center'>
                    <Grid item xs={3}>
                      <Typography>{rowData?.tenantName}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      {getComponent(rowData, ANNUAL_PLAN_RENEWAL_STAGES.RENEWAL_QUESTIONNAIRE.value)}
                    </Grid>
                    <Grid item xs={2}>
                      {getComponent(rowData, ANNUAL_PLAN_RENEWAL_STAGES.ANNUAL_RENEWAL_UPLOAD.value)}
                    </Grid>
                    <Grid item xs={2}>
                      {getComponent(rowData, ANNUAL_PLAN_RENEWAL_STAGES.PROCESSING.value)}
                    </Grid>
                    <Grid item xs={2}>
                      {getComponent(rowData, ANNUAL_PLAN_RENEWAL_STAGES.COMPLETED.value)}
                    </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                  <Box m={2} width={"100%"}>
                    <AnnualPlanRenewalDetailPanel rowData={rowData}/>
                  </Box>
              </AccordionDetails>
          </Accordion>
        )}
    </>
  );
};

export default AnnualPlanRenewal;
