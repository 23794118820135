import { Button, Grid, TextField, Typography, Box } from '@material-ui/core';
import { AppConfig } from 'AppConfig';
import axios from 'axios';
import { setAxiosHeaderToken } from 'networking/NetworkManger';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import CasClient, { constant } from 'react-cas-client';
import { useHistory } from 'react-router-dom';
import RouteConfig from 'routes/RouteConfig';
import LocalStore from 'utils/LocalStore';
import { LOGIN_SUCCESS } from 'features/user/userTypes';
import { fetchData } from 'features/user/userActions';
import { store } from 'store/store';
import { APPSTATE_SET_APP_ERROR } from 'store/actions/appstate';
import { LOGOUT_CAS } from 'features/masterData/util/CommonUtil';
import Auth from 'auth/Auth';

const Login = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const casEndpoint = AppConfig.CAS_END_POING;
  const casOptions = {
    version: constant.CAS_VERSION_3_0,
    path: '/',
    validation_proxy_path: '/cas_proxy',
    pgtUrl: AppConfig.CAS_PROXY_CALLBACK
  };
  const casClient = new CasClient(casEndpoint, casOptions);

  const [showOtp, setShowOtp] = useState(false);
  const [otpErrorMsg, setOtpErrorMsg] = useState(null);
  const [inputOtp, setInputOtp] = useState(null);

  const loginToFo = (pgtIou) => {
    axios
      .post(AppConfig.FO_INITIAL_URL + `/auth/login/verify-app`, {
        pgtIou: pgtIou,
        bstdevice: LocalStore.getBstDevice()
      })
      .then((res) => {
        const loginDto = res.data;
        if (loginDto.deviceMatched !== true) {
          setShowOtp(true);
          if (loginDto.bstDeviceToken) {
            LocalStore.saveBstDevice(loginDto.bstDeviceToken);
          }
        } else {
          updateDetail(loginDto);
        }
      })
      .finally(() => {
        LocalStore.saveVerifyOtp('false');
      });
  };

  const forceLogout = () => {
    LOGOUT_CAS();
  };

  const updateDetail = (loginDto) => {
    if (loginDto.otpMatched === true) {
      setShowOtp(false);
      setAxiosHeaderToken(loginDto.jwt);
      LocalStore.saveAccessToken(loginDto.jwt);
      if (loginDto.bstDeviceToken) {
        LocalStore.saveBstDevice(loginDto.bstDeviceToken);
      }
      dispatch({ type: LOGIN_SUCCESS, payload: loginDto });
      fetchData(loginDto.currentTenantCd, loginDto.currentApp);
      history.push(RouteConfig.newHome.path);
    } else {
      setOtpErrorMsg('The code is not correct.');
    }
  };
  const handleVerify = () => {
    LocalStore.saveVerifyOtp('true');
    setOtpErrorMsg(null);
    axios
      .post(AppConfig.FO_INITIAL_URL + '/auth/otp/verify-app', {
        otpCode: inputOtp,
        pgtIou: LocalStore.getCasPgtIou(),
        bstdevice: LocalStore.getBstDevice()
      })
      .then((res) => {
        updateDetail(res.data);
      })
      .finally(() => LocalStore.saveVerifyOtp('false'));
  };

  useEffect(() => {
    if (Auth.getLoginMode() !== Auth.LOGIN_MODE_BST) {
      LocalStore.clear();
      Auth.setLoginMode(Auth.LOGIN_MODE_BST);
    }
    const jwt = LocalStore.getAccessToken();
    if (jwt) {
      history.push(RouteConfig.newHome.path);
      return;
    }
    const pgtIou = LocalStore.getCasPgtIou();

    if (!pgtIou) {
      LocalStore.saveAccessToken('');
      casClient
        .auth()
        .then((successRes) => {
          const loggedInUser = successRes.user;
          LocalStore.saveLoginUser(loggedInUser);
          LocalStore.saveCasPgtIou(successRes.proxyGrantingTicket);

          loginToFo(successRes.proxyGrantingTicket);
        })
        .catch((errorRes) => {
          forceLogout();
          if (errorRes.code === 'INVALID_PROXY_CALLBACK') {
            store.dispatch({
              type: APPSTATE_SET_APP_ERROR,
              payload:
                'Error occurred during Login. Please contact your system administrator! ErrorCode: -1'
            });
          } else {
            store.dispatch({
              type: APPSTATE_SET_APP_ERROR,
              payload: 'Login error: ' + errorRes
            });
          }
        });
    } else {
      loginToFo(pgtIou);
    }
  }, []);

  return (
    showOtp === true && (
      <Box
        style={{
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#fafafa'
        }}>
        <Box
          sx={{
            backgroundColor: '#fff',
            width: '100%',
            maxWidth: '512px',
            padding: '60px 100px',
            borderRadius: '2px',
            boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.1)'
          }}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
              <Typography>
                Please enter the code from verification email
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="input-otp"
                name="input-otp"
                variant="outlined"
                inputProps={{
                  maxLength: '10',
                  pattern: '[0-9]{5,10}'
                }}
                fullWidth
                placeholder="Enter code"
                error={!!otpErrorMsg}
                helperText={otpErrorMsg}
                onChange={(event) => setInputOtp(event.target.value)}
                style={{ marginBottom: '10px' }}
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  handleVerify();
                }}
                fullWidth>
                Submit
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    )
  );
};

export default Login;
