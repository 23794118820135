import axios from 'axios';
import { store } from 'store/store';
import { APPSTATE_SET_IS_FETCHING_DATA_FROM_SERVER } from 'store/actions/appstate';
import LocalStore from 'utils/LocalStore';
import { APPSTATE_SET_APP_ERROR } from 'store/actions/appstate';
import * as Constant from 'common/Constant';
import { AppConfig } from '../AppConfig';
import { CLEAN_SESSION } from 'features/masterData/util/CommonUtil';
import history from './history';

export const setAxiosHeaderToken = (token) => {
  sessionStorage.setItem('token_a', token);
};

export const getCurrentAccessToken = () => sessionStorage.getItem('token_a');

const CancelToken = axios.CancelToken;

const TOKEN_EXPIRE = 'TOKEN_IS_EXPIRED';

const shouldShowSpinner = (config) => {
  return !config.url.endsWith(AppConfig.REFRESH_TOKEN_URL);
};

const mapAxiosErrorToText = (axiosError) => {
  const UNKNOWN_ERROR = 'An unknown error occurred.';

  if (axiosError && axiosError.response) {
    if (axiosError.response.data) {
      const { errorCode, message } = axiosError.response.data;
      switch (errorCode) {
        case 'DIVISION_END_BEFORE_START':
          return 'The division end date must be after the start date';
        case 'DIVISION_MISSING_START_END':
          return 'The division must include both a start and end date, or neither';
        case 'DIVISION_CONFLICT':
          return message;
        case 'SIZE_OVERFLOW':
          return 'Input or asking value is too large';
        case Constant.ERROR_CODE.MANDATORY_FIELD_MISSING:
          return message;
        case Constant.ERROR_CODE.NO_COMPANY_AVAILABLE:
          return (
            'No Company is Available for logged in User. Please contact administrator. Code: ' +
            errorCode
          );
        case Constant.ERROR_CODE.USER_IS_INACTIVE:
          return 'Your account is inactivated ' + errorCode;
        case Constant.ERROR_CODE.NO_TABLEAU_SITE_AVAILABLE:
          return message;
        case Constant.ERROR_CODE.NO_APP_AVAILABLE:
          return (
            'No Application is Available for logged in User. Please contact administrator. Code: ' +
            errorCode
          );
        case Constant.ERROR_CODE.USER_NOT_FOUND:
          return (
            'No User is found!. Please contact administrator. Code: ' +
            errorCode
          );
        case Constant.ERROR_CODE.OPT_NOT_MATCH:
          return 'Code is not matched';
        case Constant.ERROR_CODE.DELETION_FAILED:
        default:
          return message;
      }
    }

    switch (axiosError.response.status) {
      case 400:
        return 'The request was not valid. Please verify the data and try again.';
      case 401:
      case 403:
        return 'You do not have permission for that request.';
      case 500:
        return 'There was an internal server error. Please try again later.';
      default:
        return UNKNOWN_ERROR;
    }
  }

  return UNKNOWN_ERROR;
};

let isRefreshing = false;

axios.interceptors.request.use(
  (config) => {
    if (shouldShowSpinner(config)) {
      store.dispatch({
        type: APPSTATE_SET_IS_FETCHING_DATA_FROM_SERVER,
        payload: true
      });
    }
    /* cancel all requests when we switch tenant and application */
    if (
      isRefreshing === true &&
      config.url.endsWith(AppConfig.APP_TENANT_SWITCHING_URL) === false
    ) {
      const source = CancelToken.source();
      config.cancelToken = source.token;

      source.cancel();
      return config;
    }

    if (
      config.url.endsWith(AppConfig.APP_TENANT_SWITCHING_URL) === true &&
      isRefreshing === false
    ) {
      isRefreshing = true;
    }

    if (LocalStore.getAccessToken()) {
      config.headers['Authorization'] = `Bearer ${LocalStore.getAccessToken()}`;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    if (shouldShowSpinner(response.config)) {
      store.dispatch({
        type: APPSTATE_SET_IS_FETCHING_DATA_FROM_SERVER,
        payload: false
      });
    }
    if (
      response.config.url.endsWith(AppConfig.APP_TENANT_SWITCHING_URL) === true
    ) {
      isRefreshing = false;
    }
    return response;
  },
  function (error) {
    store.dispatch({
      type: APPSTATE_SET_IS_FETCHING_DATA_FROM_SERVER,
      payload: false
    });

    if (axios.isCancel(error)) {
      return Promise.reject(error);
    }

    /* todo: we may need to add 401 and 403 checking to help browser to recover 
       when they are login issue. However, the backend code needs to be cleaned up 
       first because the back-end code may return 401 incorrectly. */
    if (
      error.response &&
      error.response.data &&
      error.response.data.errorCode === TOKEN_EXPIRE
    ) {
      /* when the react state is changed, app.js will be reloaded to logout. */
      CLEAN_SESSION();
    } else {
      store.dispatch({
        type: APPSTATE_SET_APP_ERROR,
        payload: mapAxiosErrorToText(error)
      });
    }
  }
);
